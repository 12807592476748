<template>
   <dt-layout-dialog ref="confirm" title="友情提示" cssname="confirm-normal">
            <template v-slot:context="{ setSlotRef, getDialogRef }">
                <div class="confirm-wrap cs-flex-col">
                    <div class="confirm-wrap-msg"> {{msg}} </div>
                    <div class="cs-flex dt-insert-bar cs-margin-top-10 cs-margin-bottom-20">
                        <ElButton type="primary" @click.stop="submit() || $refs['confirm'].hide()">确定</ElButton>
                        <ElButton @click.stop="$refs['confirm'].hide()">取消</ElButton>
                    </div>
                </div>
            </template>
        </dt-layout-dialog>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";
export default defineComponent({
  name: "confirmDia",
  title: "确认弹框",
});
</script>
<script setup lang="ts">
const confirm = ref();
const msg = ref('');
const emits = defineEmits([
  "submit",
  "hide",
]);
const submit =()=> {
    emits('submit');
}
const showFn = (params?:any)=>{
    msg.value = params.msg
    confirm.value.show();
}
defineExpose({showFn})
</script>

<style>

</style>