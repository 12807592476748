<template>
  <template v-for="item in menuData">
    <el-sub-menu
      popper-class="eqms-popper"
      :key="item.name"
      v-if="item.children && item.children.length > 0"
      :index="item.name"
      class="el-menu-self"
    >
      <template #title>
        <!-- <img :src="item.iconCur" alt=""> -->
        <img :src="item.icon" alt="" class="title-icon" />
        <span class="title-txt">{{ item.memo }}</span>
      </template>
      <el-menu-item
        v-for="child in item.children"
        :key="child.name"
        :index="child.name"
      >
        <p class="two-txt">
          <span>{{ child.memo }}</span>
        </p>
      </el-menu-item>
    </el-sub-menu>
    <el-menu-item
      :key="item.name"
      v-else
      :index="item.name"
      :disabled="item.disabled"
    >
      <template #title>
        <span>{{ item.memo }}</span>
      </template>
    </el-menu-item>
  </template>
</template>

<script lang="ts">
import { computed, defineComponent } from "vue";
import { useAssets } from "@/cores/jsexts/resource";
export default defineComponent({
  name: "LeftSubMenu",
  title: "左侧菜单子栏",
});
</script>
<script lang="ts" setup>
import { Router, useRouter } from "vue-router";
const router: Router = useRouter();
const currRouter: any = router.currentRoute;
const props = defineProps({
  menuData: {
    type: Array<any>,
    default: () => [],
  },
  openFirst: {
    type: String,
    defaule: "",
  },
});

const curRoute = computed(() => {
  const path = currRouter;
  return path;
});
const chooseIcon = (item: any) => {
  let n = 0; // 用于判断当前一级菜单下的二级菜单是否被点击
  for (var i = 0; i < item.children.length; i++) {
    if (item.children[i].path == curRoute) {
      n = 1;
    }
  }
  if (n == 1) {
    // 被点击了，返回高亮图标
    return item.iconCur;
  } else {
    // 未被点击，返回未高亮图标
    return item.icon;
  }
};
</script>

<style lang="scss" scoped></style>
<style lang="scss">
.main-left .menu-bar .el-menu .el-menu-self .el-menu{
  padding-left: 0;
}
.el-menu-self {
  width: 212px;
  .el-sub-menu__title {
    box-sizing: border-box;
    height: 48px;
    padding: 0 14px;
    border-radius: 8px;
    .title-icon {
      width: 24px;
      height: 24px;
      margin-right: 6px;
      opacity: 0.8;
    }
    .title-txt {
      font-size: 14px;
      color: #ffffff;
      opacity: 0.8;
    }
    .el-sub-menu__icon-arrow {
      color: #fff !important;
      opacity: 0.8;
    }
    &:hover {
      background: rgba(255, 255, 255, 0.16);
      border-radius: 8px;
      .title-icon,
      .title-txt {
        opacity: 1;
      }
      .el-sub-menu__icon-arrow {
        opacity: 1;
      }
    }
  }
  .el-menu {
   
    .el-menu-item {
      padding-left: 49px !important;
      margin-bottom: 4px;
      border-radius: 8px;
      .two-txt {
        span {
          font-size: 14px;
          color: #ffffff;
          opacity: 0.8;
        }
      }
      &:hover,
      &.is-active {
        background: rgba(255, 255, 255, 0.08) !important;
        border-radius: 8px;
        .two-txt {
          span {
            opacity: 1;
          }
        }
      }
    }
  }
}
</style>
