<template>
  <div class="index-wrap">
    <!-- <img src="@/assets/imgs/layout/index.jpg" alt="" class="welcome-wrap" /> -->
    <div class="welcome-wrap">
      <div class="welcome-con">
        <div class="welcome-top">
          <h2>成为权威的、一流的检测技术服务机构</h2>
          <p>BECOMING AN AUTHORITATIVE AND FIRST-CLASS TESTING</p>
        </div>
        <div class="welcome-c cs-flex-bc">
          <div class="welcome-c-item">
            <p class="cs-flex-sc" @click="goChuli">
              <span>待我处理</span>
              <el-icon color="#333">
                <ArrowRight />
              </el-icon>
            </p>
            <div class="welcome-c-card cs-flex chuli" @click="goChuli">
              <div v-if="!listToMe.length" class="empty cs-flex-col">
                <img src="@/assets/imgs/index/empty.png" />
                <p>暂时没有需要处理的事项</p>
              </div>
              <ul class="tome-card" v-else>
                <li class="tome-title cs-flex-sc">
                  <span>功能名称</span><span>申请人</span><span>申请时间</span>
                </li>
                <li
                  v-for="item in listToMe"
                  :key="item.id"
                  class="tome-item cs-flex-sc"
                >
                  <span>{{ item.table_desc }}</span
                  ><span>{{ item.create_name }}</span
                  ><span>{{ item.create_time }}</span>
                </li>
              </ul>
            </div>
          </div>
          <div class="welcome-c-item">
            <p class="cs-flex-sc">
              <span>行业匠心</span>
              <el-icon color="#333">
                <ArrowRight />
              </el-icon>
            </p>
            <div class="welcome-c-card">
              <el-carousel class="list-craft" v-if="listCraft.length">
                <el-carousel-item
                  v-for="(item, index) in listCraft"
                  :key="item.id"
                >
                  <el-image
                    :src="item.turning_picture"
                    fit="cover"
                    class="list-craft-img"
                  />
                </el-carousel-item>
              </el-carousel>
              <el-image
                v-else
                :src="defaultCraft"
                fit="cover"
                class="list-craft-img"
              />
            </div>
          </div>
          <div class="welcome-c-item">
            <p class="cs-flex-sc">
              <span>先行者探索</span>
              <el-icon color="#333">
                <ArrowRight />
              </el-icon>
            </p>
            <div class="welcome-c-card">
              <el-carousel class="list-craft" v-if="listIndustry.length">
                <el-carousel-item
                  v-for="(item, index) in listIndustry"
                  :key="item.id"
                >
                  <el-image
                    :src="item.turning_picture"
                    fit="cover"
                    class="list-craft-img"
                  />
                </el-carousel-item>
              </el-carousel>
              <el-image
                v-else
                :src="defaultIndustry"
                fit="cover"
                class="list-craft-img"
              />
            </div>
          </div>
        </div>
        <div class="welcome-b">
          <div class="cs-flex-sc welcome-b-top">
            <p class="changyong">我的常用</p>
            <p class="gaunli" @click="cardClick">管理</p>
          </div>
          <div class="cs-flex-bc welcome-b-b">
            <div
              class="welcome-b-card"
              v-for="(item, index) in listChangyong"
              :key="index"
              :class="{ 'add-card': item.isClick }"
              @click="cardClick(item)"
            >
              <el-image :src="item.img" fit="cover" class="changyong-img" />
              <p>{{ item.funct_name }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";
export default defineComponent({
  name: "indexPage",
  title: " 首页",
});
</script>
<script setup lang="ts">
import {
  inject,
  onMounted,
  reactive,
  ref,
  onBeforeUnmount,
  shallowRef,
} from "vue";
import { Router, useRouter, onBeforeRouteLeave } from "vue-router";
import { UserInfo, userLogout, forceUserInfo } from "@/cores/stores/user";
import { useStorage, Storages } from "@/cores/stores/index";
import * as Toast from "@darrytech/extends/utilities/toastutil";
import { useAssets } from "@/cores/jsexts/resource";
import { ArrowRight } from "@element-plus/icons-vue";
const router: Router = useRouter();
const network: any = inject("$network");
const usrinfo: UserInfo = useStorage(Storages.USERINFO);
const defaultIndustry = useAssets("imgs/layout/default-Industry.png");
const defaultCraft = useAssets("imgs/layout/default-craft.png");
const changyongImgs = [
  useAssets("imgs/index/card0.png"),
  useAssets("imgs/index/card1.png"),
  useAssets("imgs/index/card2.png"),
  useAssets("imgs/index/card3.png"),
  useAssets("imgs/index/card4.png"),
  useAssets("imgs/index/card5.png"),
];
const listCraft = ref([]) as any;
const listIndustry = ref([]) as any;
const listChangyong = ref([]) as any;
const getListCraftsmanship = () => {
  let params = {
    pageIndex: 1,
    pageSize: 5,
    check_state:3

  };
  network.dteqms.listCraftsmanship(params).then((rsp: any) => {
    listCraft.value =
      rsp?.rows.map((v) => {
        return { turning_picture: v.turning_picture, id: v.id };
      }) || [];
  });
};
const getListIndustryExplore = () => {
  let params = {
    pageIndex: 1,
    pageSize: 5,
    check_state:3
  };
  network.dteqms.listIndustryExplore(params).then((rsp: any) => {
    listIndustry.value =
      rsp?.rows.map((v) => {
        return { turning_picture: v.turning_picture, id: v.id };
      }) || [];
  });
};
const getBaseFunct = () => {
  let params = {
    pageIndex: 1,
    pageSize: 6,
  };
  network.dteqms.listBaseFunctUserCommon(params).then((rsp: any) => {
    listChangyong.value = (rsp?.rows || []).map((v, i) => {
      let cur = v;
      cur.img = changyongImgs[i];
      return cur;
    });

    if (listChangyong.value.length < 6) {
      let newArr = new Array(6 - listChangyong.value.length).fill({
        funct_name: "新增常用",
        img: useAssets("imgs/index/add.png"),
        isClick: true,
      });
      listChangyong.value = [...listChangyong.value, ...newArr];
    }
  });
};
const cardClick = (item?: any) => {
  let funct_path = "";
  let funct_name = "";
  if (!item) {
    funct_path = "baseFunctUserCommonPage";
    funct_name = "常用功能"
  } else {
    funct_path = item.isClick
      ? "baseFunctUserCommonPage"
      : item.funct_path || "baseFunctUserCommonPage";
      funct_name = item.isClick
      ? "常用功能"
      : item.funct_name || "常用功能";
  }
  router.push({ name: funct_path });
  const allRoutes = router.getRoutes();
  const routerFilter: any = allRoutes.filter((item) => item.name == funct_path);
  if (!usrinfo.tabs_router) usrinfo.tabs_router = [];
  if (usrinfo.tabs_router.length) {
    let copyItem = usrinfo.tabs_router.find((item) => item.name == funct_path);
    if (!copyItem)
      usrinfo.tabs_router?.push({
        title: funct_name,
        name: funct_path,
      });
  } else {
    usrinfo.tabs_router?.push({
      title: funct_name,
      name: funct_path,
    });
  }
  forceUserInfo(usrinfo);
};

const listToMe = ref([]);
const getListToMe = () => {
  let params = {
    pageIndex: 1,
    pageSize: 5,
  };
  network.dteqms.listCheckAuditingToMe(params).then((rsp: any) => {
    listToMe.value = rsp?.rows || [];
    if (listToMe.value.length > 5) listToMe.value = listToMe.value.slice(0, 5);
  });
};
const goChuli = () => {
  cardClick({ funct_path: "waitingForMePending", funct_name: "待我处理" });
};
onMounted(() => {
  if (usrinfo.token) {
    getListCraftsmanship();
    getListIndustryExplore();
    getBaseFunct();
    getListToMe();
  }
});
</script>
<style lang="scss" scoped>
.index-wrap {
  width: 100%;
  height: 100%;
  // position: relative;
  .welcome-wrap {
    width: 100%;
    height: 100%;
    // position: absolute;
    // width: calc(100% + 48px);
    // height: calc(100% + 20px);
    // top: -10px;
    // left: -24px;
    .welcome-con {
      box-sizing: border-box;
      width: 100%;
      height: 100%;
      padding: 130px 60px 100px;
      background: url("@/assets/imgs/index/welcome-bg.png") no-repeat 0 0;
      background-size: 100% auto;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .welcome-top {
        h2 {
          font-size: 36px;
          font-family: "FZLanTingHei-Medium";
          color: #333333;
          line-height: 42px;
        }
        p {
          font-family: "FZLanTingHei-Regular";
          font-size: 11px;
          color: #888888;
          padding-top: 12px;
        }
      }
      .welcome-c {
        padding-top: 130px;
        .welcome-c-item {
          p {
            font-size: 16px;
            color: #000000;
            padding-bottom: 10px;
            font-weight: bold;
          }
          .chuli {
            cursor: pointer;
          }
          .welcome-c-card {
            width: 500px;
            height: 285px;
            background: #ffffff;
            box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.04),
              0px 2px 2px 0px rgba(0, 0, 0, 0.04);
            border-radius: 8px 8px 8px 8px;
            overflow: hidden;
            .empty {
              img {
                width: 173px;
                height: 90px;
              }
              p {
                font-family: PingFang SC;
                font-size: 12px;
                color: #999999;
                line-height: 14px;
                padding-top: 15px;
              }
            }
          }
        }
      }
      .welcome-b {
        padding-top: 20px;
        .welcome-b-top {
          padding-bottom: 10px;
          .changyong {
            font-size: 16px;
            color: #000000;
            font-weight: bold;
          }
          .gaunli {
            cursor: pointer;
            font-size: 14px;
            color: #3d75f1;
            line-height: 16px;
            margin-left: 10px;
            padding-left: 18px;
            background: url("@/assets/imgs/index/guanli.png") no-repeat 0 center;
            background-size: 16px 16px;
          }
        }

        .welcome-b-card {
          cursor: pointer;
          box-sizing: border-box;
          width: 236px;
          height: 80px;
          padding-left: 24px;
          background: #ffffff;
          box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.04),
            0px 2px 2px 0px rgba(0, 0, 0, 0.04);
          border-radius: 8px 8px 8px 8px;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          .changyong-img {
            width: 32px;
            height: 32px;
            margin-right: 10px;
          }
          p {
            font-weight: bold;
          }
          &.add-card {
            width: 234px;
            height: 78px;
            border: 1px dashed #d0d3d6;
           
            p {
              color: #888888;
            }
          }
        }
      }
    }
  }
}
.list-craft {
  width: 100%;
  height: 100%;
  :deep(.el-carousel__indicators) {
    bottom: 10px;
    left: 30px;
    transform: translateX(0);
    .el-carousel__indicator {
      .el-carousel__button {
        width: 6px;
        height: 6px;
        background-color: rgba(168, 199, 250, 0.5);
        border-radius: 17px 17px 17px 17px;
      }
      &.is-active {
        .el-carousel__button {
          width: 18px;
          height: 6px;
          background-color: #2c66ff;
          border-radius: 17px 17px 17px 17px;
        }
      }
    }
  }
  .list-craft-img {
    width: 100%;
    height: 100%;
  }
}
.icon-r {
  width: 16px;
  height: 16px;
}
.tome-card {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  padding: 20px;
  .tome-title {
    font-size: 12px;
    color: #999999;
    padding-bottom: 10px;
  }
  .tome-item {
    font-weight: 500;
    font-size: 14px;
    color: #333333;
    border-bottom: 1px solid #e9edf0;
    padding: 10px 0;
    &:last-child {
      border-bottom: none;
    }
  }
  .tome-title,
  .tome-item {
    span {
      &:nth-of-type(1) {
        width: 30%;
      }
      &:nth-of-type(2) {
        width: 30%;
      }
      &:nth-of-type(3) {
        width: 38%;
      }
    }
  }
}
</style>
