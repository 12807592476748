<template>
  <div>
    <dt-layout-dialog ref="userAuthTreeDia" title="关联用户" cssname="mind-dia" @hide="diaHide">
      <!--  v-slot:context="{ setSlotRef, getDialogRef }" -->
      <template v-slot:context="{  }">
        <div class="cs-flex-col-ss dttablelist">
          <div class="cs-flex-sc cs-margin-bottom-10 cs-width-p100 dttablelist-filter">
            <div class="cs-flex-sc cs-flex-1">
              <div class="cs-flex-bc dt-form-form cs-margin-right-10">
                <div class="cs-flex-sc cs-flex-wrap dt-form-form-wrap nomore">
                  <div class="dt-form-item">
                    <el-select class="dt-base-select" v-model="formData.type" placeholder="请选择单位类型" @change="deptChange">
                      <el-option v-for="item in deptOptions" :key="item.value" :label="item.label" :value="item.value"></el-option>
                    </el-select>
                  </div>
                  <div class="form-icon-right cs-flex">
                    <img src="@/assets/imgs/layout/tree-right.png" />
                  </div>
                  <div class="dt-form-item">
                    <el-select class="dt-base-select" placeholder="请选择单位" v-model="formData.dept_id" @change="baseDepChange">
                      <el-option v-for="item in baseDeptOption" :key="item.value" :label="item.label" :value="item.value"></el-option>
                    </el-select>
                  </div>
                  <div class="form-icon-right cs-flex">
                    <img src="@/assets/imgs/layout/tree-right.png" />
                  </div>
                  <div class="dt-form-item">
                    <el-select class="dt-base-select" placeholder="请选择人员" v-model="formData.uid" @change="baseUserChange">
                      <el-option v-for="item in baseUserOption" :key="item.value" :label="item.label" :value="item.value"></el-option>
                    </el-select>
                  </div>
                </div>
              </div>
              <div class="cs-flex dt-base-buttons">
                <el-button memo="重置" @click.stop="resetFn">重置</el-button>
              </div>
            </div>

            <el-button class="user-auth-btn" @click.stop="assUserFn">关联新用户</el-button>
          </div>
          <div class="cs-width-p100 dttablelist-table">
            <el-table :data="tableData" class="dt-form-table cs-width-p100">
              <el-table-column prop="un" label="用户" />
              <el-table-column prop="rn" label="角色" />
              <el-table-column prop="dn" label="单位" />
              <el-table-column fixed="right" label="操作">
                <template #default="scope">
                  <el-button @click.stop="authFn(scope.row)">授权</el-button>
                  <el-button @click.stop="delFn(scope.row)">删除</el-button>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <div class="cs-flex-ec cs-width-p100 cs-margin-top-10 dttablelist-btn">
            <el-pagination v-if="pageShow" v-model:currentPage="formData.pageIndex" v-model:pageSize="formData.pageSize" :total="formData.totals" :layout="pageConfig.layout" :defaultPageSize="pageConfig.pageSize"
              :defaultCurrentPage="pageConfig.pageIndex" class="dt-form-pagination" @size-change="handleSizeChange" @current-change="handleCurrentChange" @change="handlePageChange" />
          </div>
        </div>
      </template>
    </dt-layout-dialog>
    <assuserView ref="assuserWrapRef" @submitForm="resetList"></assuserView>
    <authView ref="authWrapRef" @submitForm="authSubmit"></authView>
    <confirmDia ref="confirmDiaRef" @submit="sureDiaFn"></confirmDia>
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";
export default defineComponent({
  name: "userAuthTree",
  title: "关联用户",
});
</script>
<script setup lang="ts">
import assuserView from "./assuserDia.vue";
import authView from "./authDia.vue";
import confirmDia from "@/comps/confirmDia/index.vue";
import * as Toast from "@darrytech/extends/utilities/toastutil";
import {
  inject,
  onMounted,
  reactive,
  ref,
  toRaw,
  onBeforeUnmount,
  shallowRef,
  nextTick,
} from "vue";
const props = defineProps({
  regionid: String,
});
const network: any = inject("$network");
const confirmDiaRef = ref();
const userAuthTreeDia = ref();
const assuserWrapRef = ref();
const authWrapRef = ref();
const tableData = ref([]) as any;
const curTableRow = ref({}) as any;
const pageConfig = reactive({
  pageIndex: 1,
  pageSize: 10,
  total: 0,
  pagerCount: 7,
  layout: "prev,pager,next,sizes,->,total",
  pageSizes: [10, 15, 20, 30, 40, 50, 80, 100, 200],
});
const pageShow = ref(false);
const curJie = reactive({
  id: undefined,
  level: undefined,
  name: undefined,
  pid: undefined,
});
const formData = reactive({
  project_id: undefined, // 当前节点的id
  type: undefined,
  dept_id: undefined,
  uid: undefined,
  pageIndex: 1,
  pageSize: 10,
  totals: 0,
});
const deptOptions = ref<any>([]);
const baseDeptOption = ref<any>([]);
const baseUserOption = ref<any>([]);
const getDeptOption = () => {
  network.dtsystem.listBaseDict({ group_id: 121 }).then((rsp: any) => {
    deptOptions.value = rsp.rows.map((v) => {
      return {
        label: v.item_name,
        value: v.item_id,
      };
    });
  });
};
const deptChange = (val: any) => {
  network.dteqms
    .listBaseDept({ type: val, pageIndex: 1, pageSize: 9999 })
    .then((rsp: any) => {
      baseDeptOption.value = rsp.rows.map((v) => {
        return {
          label: v.name,
          value: v.id,
        };
      });
    });
};
const baseDepChange = (val: any) => {
  network.dteqms
    .listBaseUser({
      type: formData.type,
      dept_id: val,
      pageIndex: 1,
      pageSize: 9999,
    })
    .then((rsp: any) => {
      baseUserOption.value = rsp.rows.map((v) => {
        return {
          label: v.name,
          value: v.id,
        };
      });
    });
};
const baseUserChange = (val: any) => {
  getListProjectGrants();
};
const getListProjectGrants = () => {
  let param = Object.assign({}, formData);
  network.dteqms.listProjectGrants(param).then((rsp: any) => {
    tableData.value = rsp.rows;
    formData.totals = rsp.totals;
    formData.pageIndex = rsp.pageIndex;
    formData.pageSize = rsp.pageSize;
  });
};
const handleSizeChange = (val: number) => {};
const handleCurrentChange = (val: number) => {};
const handlePageChange = (currentPage: number, pageSize: number) => {
  getListProjectGrants();
};
const resetFn = () => {
  formData.type = undefined;
  formData.dept_id = undefined;
  formData.uid = undefined;
  getListProjectGrants();
};
const authFn = (item: any) => {
  // 弹出授权框
  curTableRow.value = item;
  authWrapRef.value.show(item.g);
};
const authSubmit = (treeVal: any) => {
  network
    .wrapinvoke({
      module: "dteqms",
      apiname: "editBaseUserRole",
      apiparam: JSON.stringify({
        id: curTableRow.value.id,
        grants: treeVal,
      }),
    })
    .then((rsp: any) => {
      Toast.info(`操作成功！`);
      getListProjectGrants();
    });
};
const delFn = (item: any) => {
  curTableRow.value = item;
  confirmDiaRef.value.showFn({ msg: "确定删除该数据吗？" });
};
const sureDiaFn = () => {
  network.dteqms
    .deleteBaseUserRole({ id: curTableRow.value.id })
    .then((rsp: any) => {
      Toast.info("删除成功！");
      getListProjectGrants();
    });
};
const assUserFn = () => {
  let typeDept = "";
  let regionid: any = props.regionid;
  if (curJie.level == "2") {
    typeDept = "1,2,3";
    regionid = `1,${props.regionid}`;
  } else if (curJie.level == "3") {
    typeDept = "1,4";
  } else if (curJie.level == "5") {
    typeDept = "1,5";
    regionid = null;
  } else if (curJie.level == "6") {
    typeDept = "1,6";
    regionid = null;
  }
  if (typeDept) assuserWrapRef.value.show(curJie, typeDept, regionid);
};
const resetList = () => {
  getListProjectGrants();
};
const diaHide = () => {
  pageShow.value = false;
  Object.assign(formData, {
    project_id: undefined,
    type: undefined,
    dept_id: undefined,
    uid: undefined,
    // pageIndex: 1,
    // pageSize: 10,
    // totals: 0,
  });
};
const show = (param: any) => {
  getDeptOption();
  Object.assign(formData, {
    project_id: param.id,
    type: undefined,
    dept_id: undefined,
    uid: undefined,
    pageIndex: 1,
    pageSize: 10,
    totals: 0,
  });
  // formData.project_id = param.id;
  Object.assign(
    curJie,
    {
      id: undefined,
      level: undefined,
      name: undefined,
      pid: undefined,
    },
    param
  );
  pageShow.value = true;
  getListProjectGrants();
  userAuthTreeDia.value.show();
};
defineExpose({ show });
</script>
<style lang="scss" scoped>
.dttablelist {
  width: 995px;
  background: #fff;
  padding: 0 20px 20px;
  .dttablelist-filter {
    .dt-form-form {
      .form-icon-right {
        width: 5px;
        height: 13px;
        padding: 0 5px;
        img {
          width: 5px;
          height: 13px;
        }
      }
    }
    :deep(.dt-base-input),
    :deep(.dt-base-select) {
      width: 200px !important;
      height: 32px !important;
      margin-right: 0px !important;
      --el-disabled-bg-color: #122412;
      --el-disabled-border-color: #3b5a84;
      --el-disabled-text-color: #e1f0e1;

      .el-input__wrapper,
      .el-select__wrapper {
        box-sizing: border-box;
        width: 198px !important;
        height: 30px !important;
        background: #fff;
        border: 1px solid #cdd4df;
        box-shadow: none;
        border-radius: 4px;

        &.is-hovering:not(.is-foscued) {
          border: 1px solid #cdd4df;
          box-shadow: none;
        }
      }

      .el-input__inner {
        font-size: 18px;
        color: #000;
        -webkit-text-fill-color: #000;
        font-family: PingFang SC;

        &::placeholder {
          color: #9fafbc;
        }
      }
    }
    .dt-base-buttons {
      width: auto;
      padding: 0;
    }
    :deep(.user-auth-btn) {
      width: 122px;
      height: 32px;
      background: url("@/assets/imgs/layout/tree-user-auth.png") no-repeat 17px
        center #3d75f1;
      background-size: 16px 16px;
      border-radius: 4px 4px 4px 4px;
      font-size: 14px;
      color: #ffffff;
      border: none;
      span {
        padding-left: 18px;
      }
    }
  }
  .dttablelist-table {
    max-height: 500px;
    .dt-form-table {
      height: 500px;
    }
  }
}
</style>
