<template>
  <div class="login-wrap">
    <div class="login-mp4">
      <!-- <videoPlay
       v-if="isVideoPlaying"
        width="100vw"
        height="100vh"
        ref="videoPlayer"
        class="video-player"
        :playsinline="true"
        v-bind="playerOptions"
        @play="onPlay"
        @pause="onPause"
        @timeupdate="onTimeupdate"
        @canplay="onCanplay"
      ></videoPlay> -->
      <video class="video-player" playsinline loop autoplay muted width="100%" height="100%" :src="posterVideo"></video>
    </div>

    <!-- <div class="login-logo"></div> -->
    <div class="login-h">
      <h2>欢迎使用</h2>
      <h3>民航专业工程</h3>
      <h3>安全质量信息管理系统</h3>
      <p>CIVIL AIRPORT ENGINEERING SAFETY MIS</p>
    </div>
    <div class="form-wrap">
      <div class="form cs-flex-col">
        <div class="form-tab-wrap">
          <el-tabs v-model="activeName" class="form-tabs" @tab-click="changeLoginType">
            <el-tab-pane label="密码" name="password"></el-tab-pane>
            <el-tab-pane label="短信" name="msm"></el-tab-pane>
          </el-tabs>
        </div>
        <div class="user">
          <el-input placeholder="请输入手机号" v-model="formData.name" clearable autocomplete="off" @change="userChange"></el-input>
        </div>
        <div class="password" v-if="activeName == 'password'">
          <el-input placeholder="请输入密码" v-model="formData.password" type="password" clearable show-password autocomplete="off" @change="userChange"></el-input>
        </div>
        <div class="message" v-if="activeName == 'msm'">
          <div class="cs-flex-bc cs-width-p100">
            <el-input placeholder="请输入短信验证码" v-model="formData.sms_code" clearable autocomplete="off" @change="userChange" :maxlength="6"></el-input>
            <div class="cs-flex-1 before message-btn" @click="smsClick" v-if="showBeforeBtn">
              点击获取
            </div>
            <div class="cs-flex-1 after message-btn" v-if="!showBeforeBtn">
              {{ countDownTime }}s
            </div>
          </div>
        </div>
        <div class="tip cs-flex-sc">
          <div class="cs-flex" v-show="errMsg">
            <img src="@/assets/imgs/login/errortip.png" />
            <span>{{ errMsg }}</span>
          </div>
        </div>
        <div class="loginbtn">
          <el-button @click="login" @keydown.enter="keyDown()" :class="[btnAbled ? 'btn-able' : '']">登录</el-button>
        </div>
      </div>
    </div>

    <div class="beian">
      <a href="https://beian.miit.gov.cn/" target="_blank">京ICP备20026826号-3</a>
    </div>
  </div>
</template>

<script lang="ts">
import {
  defineComponent,
  onMounted,
  onUnmounted,
  onBeforeUnmount,
  ref,
  Ref,
  computed,
  reactive,
  inject,
  nextTick,
  toRefs,
} from "vue";
import { Router, useRouter } from "vue-router";
export default defineComponent({ name: "page-login", title: "登录页面" });
</script>
<script lang="ts" setup>
import "vue3-video-play/dist/style.css";
import { videoPlay } from "vue3-video-play/dist/index.mjs";
import md5 from "js-md5";
import { useAssets } from "@/cores/jsexts/resource";
import { UserInfo, forceUserInfo, userLogout } from "@/cores/stores/user";
import { useStorage, Storages } from "@/cores/stores/index";
const network: any = inject("$network");
const usrinfo: UserInfo = useStorage(Storages.USERINFO);
const router: Router = useRouter();
const windList = [
  "有风",
  "微风",
  "强风/劲风",
  "疾风",
  "大风",
  "烈风",
  "风暴",
  "狂爆风",
  "飓风",
  "热带风暴",
  "龙卷风",
];
const rainList = [
  "小雨",
  "中雨",
  "大雨",
  "暴雨",
  "大暴雨",
  "特大暴雨",
  "极端降雨",
  "毛毛雨/细雨",
  "雨",
  "小雨-中雨",
  "中雨-大雨",
  "大雨-暴雨",
  "暴雨-大暴雨",
  "大暴雨-特大暴雨",
];
const snowList = [
  "雪",
  "阵雪",
  "小雪",
  "中雪",
  "大雪",
  "暴雪",
  "小雪-中雪",
  "中雪-大雪",
  "大雪-暴雪",
];
const hazeList = ["霾", "中度霾", "重度霾", "严重霾"];
const sandstormList = ["浮尘", "扬沙", "沙尘暴", "强沙尘暴"];
const fogList = ["雾", "浓雾", "强浓雾", "轻雾", "大雾", "特强浓雾"];
const clearList = ["晴", "少云", "平静", "热", "冷", "未知"];
const cloudyList = ["多云", "晴间多云"];
const thundershowerList = [
  "阵雨",
  "雷阵雨",
  "雷阵雨并伴有冰雹",
  "强阵雨",
  "强雷阵雨",
];
const posterImg = useAssets("imgs/login/login-bg.jpg");
const posterVideo = useAssets("imgs/login/login.mp4");
const isVideoPlaying = ref(false);
const activeName = ref("password");
const formData = reactive({
  name: "",
  password: "",
  sms_code: "",
}) as any;
const codeSms = ref(); //短信验证码
const errMsg = ref("");
const btnAbled = computed(() => {
  if (activeName.value == "password") {
    return formData.name && formData.password;
  } else {
    return formData.name && formData.sms_code;
  }
});
// 获取短信验证码
const imsState = reactive({
  timer: null as any,
  countDownTime: 60,
});
const showBeforeBtn = ref(true);
const userChange = (e: any) => {
  errMsg.value = "";
};
const login = async () => {
  if (formData.name == "") {
    errMsg.value = "手机号不能为空";
    return false;
  }
  if (activeName.value == "password" && formData.password == "") {
    errMsg.value = "密码不能为空";
    return false;
  }
  if (activeName.value == "msm" && formData.sms_code == "") {
    errMsg.value = "短信验证码不能为空";
    return false;
  }
  errMsg.value = "";
  let userInfo: any = {
    phone: formData.name,
    pwd: formData.password ? formData.password : null,
    sms_code: formData.sms_code ? formData.sms_code : null,
  };

  // 调用登录接口
  network.dteqms
    .commUserLogin(userInfo)
    .then((rsp: any) => {
      if (!rsp?.project2?.length) {
        errMsg.value = "用户无权限登录";
        return;
      }
      // 存储数据
      usrinfo.name = rsp.data?.name;
      usrinfo.avatar = rsp.data?.avatar;
      usrinfo.gender = rsp.data?.gender;
      usrinfo.id = rsp.data?.id; // data用户信息
      usrinfo.dept_type = rsp.data?.dept_type;
      usrinfo.token = rsp.token;

      usrinfo.projects = rsp?.project2?.length ? [...rsp.project2] : [];
      usrinfo.project_id = rsp?.project2?.length
        ? rsp.project2[0].id
        : undefined;
      usrinfo.project_name = rsp?.project2?.length ? rsp.project2[0].name : "";
      usrinfo.project_item_id = 0;
      getGrants(usrinfo.project_id);
    })
    .catch((err) => {
      errMsg.value = err.message;
    });
};
const getGrants = (project_id: any) => {
  network.dteqms.commUserGrants({ project_id: project_id }).then((rsp: any) => {
    let userGrants = rsp.grants || [];
    let grantsMap = {};
    userGrants.forEach((item) => {
      if (item.includes("_")) {
        let fen = item.split("_");
        if (!grantsMap[fen[1]]) {
          grantsMap[fen[1]] = []; // 页面的权限
        }
        if (fen.length > 2) {
          if (fen[2] !== "M") {
            if (fen[2] == "P") {
              grantsMap[fen[1]].push(fen.slice(3).join("-"));
            } else {
              grantsMap[fen[1]].push(fen.slice(2).join("-"));
            }
          }
        } else {
          // grantsMap[fen[1]] = [];
        }
      }
    });
    console.log("grantsMap====", grantsMap);
    usrinfo.userGrants = grantsMap;
    getTianqiList();
    forceUserInfo(usrinfo);
    router.push({ name: "indexPage" });
  });
};
//点击回车键登录
const keyDown = (e?: any) => {
  if (e.keyCode == 13) {
    login();
  }
};
const smsClick = () => {
  if (formData.name == "") {
    errMsg.value = "请填写手机号！";
    return false;
  }
  errMsg.value = "";
  timeDownFn();
  network.dteqms.commUserSmsCode({ phone: formData.name }).then((rsp: any) => {
    codeSms.value = rsp.sms_code;
  });
};

const timeDownFn = () => {
  let startTime: any = localStorage.getItem("startTimeLogin");
  let nowTime: any = new Date().getTime();
  if (startTime) {
    let surplus: any = 60 - parseInt((nowTime - startTime) / 1000, 10);
    imsState.countDownTime = surplus <= 0 ? 0 : surplus;
  } else {
    imsState.countDownTime = 60;
    localStorage.setItem("startTimeLogin", nowTime);
  }
  imsState.timer = setInterval(() => {
    imsState.countDownTime--;
    showBeforeBtn.value = false;
    if (imsState.countDownTime <= 0) {
      localStorage.removeItem("startTimeLogin");
      clearInterval(imsState.timer);
      imsState.countDownTime = 60;
      showBeforeBtn.value = true;
    }
  }, 1000);
};
const changeLoginType = () => {
  formData.password = "";
  formData.sms_code = "";
  errMsg.value = "";
  localStorage.removeItem("startTimeLogin");
  if (imsState.timer) clearInterval(imsState.timer);
  imsState.countDownTime = 60;
  showBeforeBtn.value = true;
};
const videoPlayer = ref();
const playerOptions = reactive({
  // width: "100vw", //播放器宽度
  // height: "100vh", //播放器高度
  //color: "#000000",  //主题色
  title: "", //视频名称
  type: "video/mp4",
  poster: posterImg, //因为是本地图片，前面加require
  src: posterVideo, //因为是本地视频，前面加require
  speedRate: ["2.0", "1.0", "1.5", "1.25", "0.75", "0.5"], //视频倍速
  autoplay: true, //浏览器准备好时自动开始回放。
  muted: false, // 默认情况下将会消除任何音频。
  webFullScreen: false, //全屏
  loop: true, //循环播放
  mirror: false, //镜像画面
  ligthOff: false, //关灯模式
  volume: 0.3, //默认音量大小
  preload: "auto", //预加载
  //speed: false,  // 关闭进度条拖动
  language: "zh-CN",
  // aspectRatio: "16:9", // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值（值应该代表一个比例）。
  fluid: false, // 当true时，Video.js player将按比例缩放以适应其容器。

  //notSupportedMessage: '此视频暂无法播放，请稍后再试',
  //timeDivider: false,   //当前时间和持续时间的分隔符
  //durationDisplay: false,   //显示持续时间
  //remainingTimeDisplay: false,  //是否显示剩余时间功能
  //fullscreenToggle: false,  //全屏按钮
  //showPlayButton: false,   //播放按钮
  control: false, //隐藏控制条
});

const onPlay = (ev) => {
  console.log("播放");
};
const onPause = (ev) => {
  console.log(ev, "暂停");
};
const onTimeupdate = (ev) => {};
const onCanplay = (ev) => {
  console.log(ev, "可以播放");
  nextTick(() => {
    videoPlayer.value?.play();
  });
  isVideoPlaying.value = true;
};
const weatherBg = ref();
const getTianqiList = async () => {
  await network.dteqms.commWeather().then((res: any) => {
    let weather = res?.lives[0]?.weather;
    if (windList.includes(weather)) {
      weatherBg.value = "icon-winds";
    } else if (rainList.includes(weather)) {
      weatherBg.value = "icon-rain";
    } else if (snowList.includes(weather)) {
      weatherBg.value = "icon-snow";
    } else if (hazeList.includes(weather)) {
      weatherBg.value = "icon-haze";
    } else if (sandstormList.includes(weather)) {
      weatherBg.value = "icon-sandstorm";
    } else if (fogList.includes(weather)) {
      weatherBg.value = "icon-fog";
    } else if (clearList.includes(weather)) {
      weatherBg.value = "icon-clear";
    } else if (cloudyList.includes(weather)) {
      weatherBg.value = "icon-cloudy";
    } else if (thundershowerList.includes(weather)) {
      weatherBg.value = "icon-thundershower";
    } else {
      weatherBg.value = "icon-clear";
    }
    usrinfo.weatherBg = weatherBg.value;
    usrinfo.temperature = res.lives[0].temperature;
    forceUserInfo(usrinfo);
  });
};
onMounted(() => {
  //绑定监听事件
  window.addEventListener("keydown", keyDown);
  isVideoPlaying.value = true;
});
onBeforeUnmount(() => {});
onUnmounted(() => {
  //销毁事件
  window.removeEventListener("keydown", keyDown, false);
  // videoPlayer.value?.pause();
  isVideoPlaying.value = false;
});
const { countDownTime } = toRefs(imsState);
</script>
<style lang="scss" scoped>
img {
  width: 100%;
  height: 100%;
}
.login-wrap {
  width: 100vw;
  height: 100vh;
  background: url("@/assets/imgs/login/login-bg.jpg") no-repeat center center;
  background-size: 100% 100%;
  .form-wrap {
    position: fixed;
    z-index: 99;
    right: 300px;
    top: 50%;
    width: 482px;
    height: 347px;
    margin-top: -186px;
    background: #ffffff;
    box-shadow: 0px 2px 8px 0px rgba(9, 37, 94, 0.12),
      inset 2px 2px 2px 0px rgba(255, 255, 255, 0.5);
    border-radius: 16px 16px 16px 16px;
    .form {
      box-sizing: border-box;
      width: 100%;
      height: 100%;
      padding: 40px 60px 60px;
      justify-content: flex-start;
      .form-tab-wrap {
        width: 100%;
        height: 53px;
      }
      :deep(.form-tabs) {
        .el-tabs__nav-wrap::after {
          display: none;
        }
        .el-tabs__header {
          margin-bottom: 28px;
        }
        .el-tabs__item {
          font-family: PingFang SC;
          font-size: 16px;
          color: #888888;
          height: 26px;
          padding: 0 18px 2px;
          &.is-active {
            color: #2c66ff;
          }
        }
        .el-tabs__item.is-top:nth-child(2) {
          padding-left: 0;
        }
        .el-tabs__item.is-top:last-child {
          padding-right: 0;
        }
        .el-tabs__active-bar {
          background-color: #2c66ff;
          height: 2px;
        }
      }
    }

    .user,
    .password,
    .message {
      box-sizing: border-box;
      width: 362px;
      height: 40px;
      margin-bottom: 16px;
      .el-input {
        width: 362px;
        height: 40px;
        :deep(.el-input__wrapper) {
          box-sizing: border-box;
          width: 360px;
          height: 38px;
          padding: 0px 14px;
          background-color: transparent;
          border-radius: 0px;
          opacity: 1;
          box-shadow: none;
          border-radius: 4px 4px 4px 4px;
          border: 1px solid #d0d3d6;

          &.is-focus {
            border: 1px solid #2c66ff;
          }
          .el-input__inner {
            height: 100%;
            background-color: transparent;
          }
          .el-icon {
            width: 24px;
            height: 24px;
            color: #888888;

            svg {
              width: 16px;
              height: 16px;
            }
          }
        }

        :deep(.el-input__inner) {
          color: #333333;
          background-color: transparent !important;
          font-size: 16px !important;
          -webkit-text-fill-color: #333 !important;
          -webkit-box-shadow: 0 0 0 1000px rgba(21, 46, 12, 0) inset !important;
          -webkit-transition-delay: 99999s;
          -webkit-transition: color 99999s ease-out,
            background-color 99999s ease-out;
          &::placeholder {
            font-size: 16px;
            color: #999;
            font-family: PingFang SC;
            -webkit-text-fill-color: #999 !important;
          }
        }
      }
    }
    .user {
      margin-bottom: 16px;
    }
    .message {
      position: relative;
      .el-input {
        :deep(.el-input__inner) {
          width: 240px;
          height: 100%;
        }
      }
      .message-btn {
        position: absolute;
        right: 1px;
        top: 1px;
        padding-right: 16px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #2c66ff;
        font-size: 16px;
        height: 38px;
        line-height: 38px;
        &.after {
          color: #888888;
        }
      }
    }
    .tip {
      width: 100%;
      height: 16px;
      line-height: 16px;
      text-align: left;
      padding: 0px 0px 26px;
      font-size: 14px;
      color: #ea4c4c;
      img {
        display: block;
        width: 14px;
        height: 14px;
        padding-right: 5px;
      }
    }
    .loginbtn {
      :deep(.el-button) {
        cursor: pointer;
        width: 362px;
        height: 40px;
        background: #2c66ff;
        border-radius: 4px 4px 4px 4px;
        text-align: center;
        font-size: 18px;
        color: #ffffff;
        box-shadow: none;
        border: none;
        font-family: PingFang SC;
        font-weight: 500;
        letter-spacing: 5px;
        opacity: 0.4;
        &.btn-able {
          background: #2c66ff;
          color: #ffffff;
          opacity: 1;
        }
      }
    }
  }
  .login-logo {
    position: fixed;
    z-index: 99;
    left: 80px;
    top: 54px;
    width: 70px;
    height: 46px;
    background: url("@/assets/imgs/login/login-logo.png") no-repeat center
      center;
    background-size: 100% 100%;
  }
  .login-h {
    position: fixed;
    z-index: 99;
    left: 218px;
    top: 241px;
    h2,
    h3 {
      font-size: 48px;
      color: #ffffff;
      font-weight: normal;
    }
    h2 {
      font-family: "FZLanTingHei-Medium";
    }
    h3 {
      font-family: "FZLanTingHei-Regular";
    }
    p {
      font-size: 12px;
      color: #ffffff;
      padding-top: 33px;
      opacity: 0.5;
    }
  }
}
.login-mp4 {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  :deep(.video-player) {
    width: 100%;
    height: 100%;
    object-fit: cover !important;
  }
  :deep(.d-player-state) {
    display: none !important;
  }
  :deep(.d-player-input) {
    display: none !important;
  }
  :deep(.d-loading) {
    display: none !important;
  }
}

.beian {
  position: fixed;
  bottom: 20px;
  right: 20px;
  font-size: 14px;
  a {
    color: #000;
  }
}
</style>
