<template>
  <div class="main-box cs-flex-col-ss">
    <headVue @chageProject="chageModelTop"></headVue>
    <div class="main-body cs-flex-1 cs-flex-bs">
      <div class="main-body-left">
        <leftVue
          @clickMenu="clickMenu"
          @subMenuOpen="subMenuOpen"
          @subMenuClose="subMenuClose"
          ref="leftRef"
        ></leftVue>
      </div>
      <div class="main-body-right cs-flex-col">
        <div class="body-right-top">
          <topTabs @clickMenu="clickMenuCom"></topTabs>
        </div>
        <div
          class="body-right-b"
          :class="[
            usrinfo.current_router == 'indexPage' ? 'body-right-index' : '',
          ]"
        >
          <router-view v-slot="{ Component }">
            <keep-alive >
              <component
                :is="Component"
                :key="`${$route.name}${$route.meta.keepAlive}`"
                @clickMenu="clickMenuCom"
              ></component>
            </keep-alive>
          </router-view>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {
  PropType,
  defineComponent,
  ref,
  onMounted,
  computed,
  onUnmounted,
  watch,
  nextTick,
  inject,
} from "vue";
import { Router, useRouter, onBeforeRouteLeave } from "vue-router";
export default defineComponent({
  name: "mainPage",
  title: "工作区主布局",
});
</script>
<script setup lang="ts">
import { UserInfo, forceUserInfo } from "@/cores/stores/user";
import { useStorage, Storages } from "@/cores/stores/index";
import headVue from "./head.vue";
import leftVue from "./left.vue";
import topTabs from "./topTabs.vue";
const router: Router = useRouter();
const usrinfo: UserInfo = useStorage(Storages.USERINFO);

const clickMenuCom = (param: any) => {
  clickMenu(param.name, param.query);
};
const clickMenu = (menu: any, queryparam?: any) => {
  let query = queryparam ? {...queryparam, debug:1} : {debug:1};
  const allRoutes = router.getRoutes();
  const routerFilter: any = allRoutes.filter((item) => item.name == menu);
  if (!usrinfo.tabs_router) usrinfo.tabs_router = [];
  if (usrinfo.tabs_router.length) {
    let copyItem = usrinfo.tabs_router.find((item) => item.name == menu);
    if (!copyItem)
      usrinfo.tabs_router?.push({
        title: routerFilter[0]?.meta?.memo,
        name: menu
      });
  } else {
    usrinfo.tabs_router?.push({
      title: routerFilter[0]?.meta?.memo,
      name: menu
    });
  }
  forceUserInfo(usrinfo);
  router.push({ name: menu, query });
};

const leftRef = ref();
const chageModelTop = () => {
  usrinfo.tabs_router = [];
  // leftRef.value.getMenuReset();
  location.href='/';
};

// 二级菜单出现时，右侧内容整体右移

const subMenuOpen = () => {};
const subMenuClose = () => {};
const generateKey = () => {
  return (
    Math.random().toString(36).substring(2, 15) +
    Math.random().toString(36).substring(2, 15)
  );
};
</script>
<style lang="scss">
.main-box {
  width: 100vw;
  height: 100vh;
  background: linear-gradient(180deg, #0a54cc 0%, #61a7f3 100%);
  .main-body {
    box-sizing: border-box;
    width: 100%;
    overflow: hidden;
    .main-body-right {
      flex: 1;
      height: 100%;
      width: calc(100vw - 240px);
      .body-right-top {
        width: calc(100vw - 240px);
        height: 46px;
        flex: 0 0 46px;
        border-radius: 17px 0px 0px;
        background: #eaecf2;
      }
      .body-right-b {
        flex: 1;
        box-sizing: border-box;
        width: 100%;
        // padding: 10px 24px 35px;
        background: #ffffff;
        overflow: hidden;
      }
    }
  }
  .main-body-left {
    flex: 0 0 240px;
    width: calc(240px);
    height: calc(100vh - 70px);
  }
}
</style>
